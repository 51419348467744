import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { ConsultationCardContentView } from './consultation-card-content-view';

type FurPurchasePromptCardProps = {
  consultationId: string;
  practitionerName: string;
  routes: {
    consultation: {
      reviewFollowUpOrReview: (id: string) => string;
    };
  };
};

export function FurPurchasePromptCard({
  consultationId,
  practitionerName,
  routes,
}: FurPurchasePromptCardProps): ReactElement | null {
  const history = useHistory();

  return (
    <ConsultationCardContentView
      paragraphs={[
        {
          key: 'followUpOrReviewPurchaseText',
          text: (
            <FormattedMessage
              defaultMessage="{person} has prescribed you a treatment plan."
              description="Profile page follow-up or review purchase prompt text"
              values={{ person: practitionerName }}
            />
          ),
        },
        {
          key: 'followUpOrReviewPurchaseParagraph',
          text: (
            <FormattedMessage defaultMessage="View your plan and confirm your order. You will not receive your next medication order until this is complete." />
          ),
        },
      ]}
      buttons={[
        {
          key: 'followUpOrReviewPurchaseButton',
          text: (
            <FormattedMessage
              defaultMessage="View treatment plan"
              description="Profile page follow-up or review purchase prompt button"
            />
          ),
          onClick: () => {
            history.push(
              routes.consultation.reviewFollowUpOrReview(consultationId),
            );
          },
        },
      ]}
    />
  );
}
