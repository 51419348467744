import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { ConsultationCardContentView } from './consultation-card-content-view';

type ConfirmPurchasePromptCardProps = {
  consultationId: string;
  practitionerName: string;
  routes: {
    consultation: {
      activatePurchaseFlow: {
        review: (id: string) => string;
      };
    };
  };
};

export function ConfirmPurchasePromptCard({
  consultationId,
  practitionerName,
  routes,
}: ConfirmPurchasePromptCardProps): ReactElement | null {
  const history = useHistory();

  return (
    <ConsultationCardContentView
      paragraphs={[
        {
          key: 'confirmPurchaseText',
          text: (
            <FormattedMessage
              defaultMessage="{person} has prescribed you a treatment plan."
              description="Profile page confirm purchase prompt text"
              values={{ person: practitionerName }}
            />
          ),
        },
        {
          key: 'confirmPurchaseParagraph',
          text: (
            <FormattedMessage defaultMessage="View your plan and confirm your order. You will not receive your next medication order until this is complete." />
          ),
        },
      ]}
      buttons={[
        {
          key: 'confirmPurchaseButton',
          text: (
            <FormattedMessage
              defaultMessage="View treatment plan"
              description="Profile page confirm purchase prompt button"
            />
          ),
          onClick: () => {
            history.push(
              routes.consultation.activatePurchaseFlow.review(consultationId),
            );
          },
        },
      ]}
    />
  );
}
