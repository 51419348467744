import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { ConsultationCardContentView } from './consultation-card-content-view';
import { ConsultationCardProps } from '../types';
import { getConfig } from '@customer-frontend/config';

type TreatmentCreatedCardProps = {
  consultationId: string;
  doctorName: string;
  isDashboardActive?: boolean;
  isFollowUpOrReview: boolean;
  routes: {
    consultation: {
      plan: (id: string) => string;
    };
    weightLoss: {
      dashboard: string;
    };
  };
};

export function TreatmentCreatedCard({
  consultationId,
  doctorName,
  isFollowUpOrReview,
  isDashboardActive,
  routes,
}: TreatmentCreatedCardProps): ReactElement {
  const history = useHistory();
  const config = getConfig();

  const buttons: ConsultationCardProps['buttons'] = [
    {
      key: 'treatmentCreatedButton',
      text: (
        <FormattedMessage
          defaultMessage="View treatment plan"
          description="Button that navigates to the treatment plan details page"
        />
      ),
      onClick: () => {
        history.push(routes.consultation.plan(consultationId));
      },
    },
  ];

  if (isDashboardActive && config.weightDashboardEnabled) {
    buttons.push({
      key: 'treatmentActiveCardWeightButton',
      text: <FormattedMessage defaultMessage="View dashboard" />,
      onClick: () => {
        history.push(routes.weightLoss.dashboard);
      },
      level: 'secondary' as const,
    });
  }

  return (
    <ConsultationCardContentView
      paragraphs={[
        isFollowUpOrReview
          ? {
              key: 'treatmentCreatedFollowUpReview',
              text: (
                <FormattedMessage
                  defaultMessage="{person} has updated your treatment plan."
                  description="Status text telling users someone has updated their treatment plan"
                  values={{
                    person: doctorName,
                  }}
                />
              ),
            }
          : {
              key: 'treatmentCreatedInitial',
              text: (
                <FormattedMessage
                  defaultMessage="{person} has prescribed you a treatment plan."
                  description="Status text telling users someone has created a treatment plan for them."
                  values={{
                    person: doctorName,
                  }}
                />
              ),
            },
        {
          key: 'treatmentCreatedConfirm',
          text: (
            <FormattedMessage defaultMessage="View your plan and confirm your order. You will not receive your next medication order until this is complete." />
          ),
        },
      ]}
      buttons={buttons}
    />
  );
}
